import { Controller } from '@hotwired/stimulus'
import confetti from 'canvas-confetti'

export default class extends Controller {
  static targets = ['canvas']
  connect() {
    console.log("Confetti!")
    // Create confetti object
    this.confetti = confetti.create(this.canvasTarget, { resize: true })

    // Fire!
    this.fire(0.25, { spread: 26, startVelocity: 55 })
    this.fire(0.2, { spread: 60 })
    this.fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 })
    this.fire(0.1, {
      speed: 120, startVelocity: 25, decay: 0.92, scalar: 1.2,
    })
    this.fire(0.1, { speed: 120, startVelocity: 45 })

    // Remove canvas after 5 seconds
    setTimeout(() => {
      this.canvasTarget.remove()
    }, 5000)
  }

  fire(particleRatio, options) {
    const count = 200
    const defaults = { origin: { y: 0.7 } }

    this.confetti({ ...defaults, ...options, particleCount: Math.floor(count * particleRatio) })
  }
}
