export default class Sum {
  constructor(terms) {
    this.terms = terms;
  }

  evaluate() {
    let sum = 0

    this.terms.forEach(term => {
      term.dependencies.forEach(cell => cell.evaluate())

      if (term.dependencies.some(cell => !cell.valid)) {
        return { valid: false }
      }
      const sign = term.sign || 1

      term.dependencies.forEach(cell => {
        sum += cell.value * sign
      })
    });

    return { valid: true, value: sum };
  }
}
