import FlipExpensesDesc from './strategies/flip_expenses_desc.js'
import TwoIslands from './strategies/two_islands.js'

export default class StrategyFactory {
  static create(name) {
    switch (name) {
      case 'FlipExpensesDesc':
        return new FlipExpensesDesc()
      case 'TwoIslands':
        return new TwoIslands()
      default:
        throw new Error('Unknown strategy: ' + name)
    }
  }
}
