import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['clearable'];

  clear() {
    this.clearableTargets.forEach((element) => {
      element.value = '';
    });
  }
}
