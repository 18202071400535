import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['search', 'row', 'rowExpandable', 'select']

  static values = {
    rows: Array,
  }

  connect() {
    console.log("TreeController#connect")
    console.log(this.rowsValue)

    this.unsubscribe = []

    const handler = this.onSearch.bind(this)
    this.searchTarget.addEventListener('input', handler)
    this.unsubscribe.push([this.searchTarget, 'input', handler])

    this.selectTargets.forEach((e) => {
      const handler = this.onSelect.bind(this)
      e.addEventListener('click', handler)
      this.unsubscribe.push([e, 'click', handler])
    })

    this.rowExpandableTargets.forEach((e) => {
      const handler = this.onExpand.bind(this)
      e.addEventListener('click', handler)
      this.unsubscribe.push([e, 'click', handler])
    })
  }

  disconnect() {
    this.unsubscribe.forEach((u) => {
      u[0].removeEventListener(u[1], u[2])
    })
  }

  onSelect(e) {
    e.preventDefault()
    e.stopPropagation()

    console.log("TreeController#onSelect", e)

    const id = e.currentTarget.closest('[data-tree-row-id]').getAttribute('data-tree-row-id')
    const event = new CustomEvent('tree:select', { bubbles: true, cancelable: true, detail: { id  }})
    this.element.dispatchEvent(event)
  }

  onExpand(e) {
    console.log("TreeController#onSearch", e)

    const alreadyExpanded = e.currentTarget.classList.contains('is-expanded')

    this.rowTargets.forEach((e) => {
      e.classList.remove('is-expanded')
    })
    this.rowExpandableTargets.forEach((e) => {
      e.classList.remove('is-expanded')
    })

    if (alreadyExpanded) {
      e.currentTarget.classList.remove('is-expanded')
    } else {
      e.currentTarget.classList.add('is-expanded')
    }

    let parent = e.currentTarget.parentNode.closest('[data-tree-row-label]')
    let n = 0
    while (parent) {
      n += 1
      if (!alreadyExpanded || n != 1) {
        parent.classList.add('is-expanded')
      }
      parent = parent.parentNode.closest('[data-tree-row-label]')

      if (n > 100) {
        throw "Infinite loop"
      }
    }
  }

  onSearch(e) {
    console.log("TreeController#onSearch", e)

    const text = e.currentTarget.value

    if (text.length === 0) {
      this.rowTargets.forEach((e) => {
        const indent = +e.getAttribute('data-tree-row-indent')
        e.classList.remove('hidden')
        e.classList.remove('is-expanded')
      })
      return
    }

    // First, we hide all rows
    this.rowTargets.forEach((e) => {
      e.classList.add('hidden')
      e.classList.remove('is-expanded')
    })



    // Then we show the ones that match
    this.rowTargets.forEach((e) => {
      const label = e.getAttribute('data-tree-row-label')
      if (label.toLowerCase().includes(text.toLowerCase())) {
        e.classList.remove('hidden')
        e.classList.add('is-expanded')

        let parent = e.parentNode.closest('[data-tree-row-label]')
        let n = 0
        while (parent) {
          n += 1
          parent.classList.remove('hidden')
          parent.classList.add('is-expanded')
          parent = parent.parentNode.closest('[data-tree-row-label]')

          if (n > 100) {
            throw "Infinite loop"
          }
        }
      }
    })
  }
}
