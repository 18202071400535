import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'saving', 'saved', 'saveError',
    'title', 'text', 'selectedOption', 'selectedPreviousOption', 'rows', 'columns', 'comments'
  ]
  static values = { delay: { type: Number, default: 0 }, savePath: String }

  connect() {
    this.timeout = null;
    console.log(this.savePathValue)

    // In addition to explicit submit events, we also listen to the
    // CustomEvent('save', { detail: payload })) event to allow programmatic saving
    this.addSaveListener();
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  addSaveListener() {
    this.element.addEventListener('save', (event) => {
      this.submit();
    });
  }

  submit(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.save();
    }, this.delayValue);
  }

  save() {
    const body = {}

    if (this.hasTitleTarget) {
      body.title = this.titleTarget.value
    }

    if (this.hasTextTarget) {
      body.text = this.textTarget.value
    }

    if (this.hasCommentsTarget) {
      body.comments = this.commentsTarget.value
    }

    if (this.hasSelectedOptionTarget) {
      let selectedOption = Array.from(this.selectedOptionTargets).find(radio => radio.checked);

      if (selectedOption) {
        body.selected_option = selectedOption.value;
      }
    }

    if (this.hasSelectedPreviousOptionTarget) {
      let selectedPreviousOption = Array.from(this.selectedPreviousOptionTargets).find(radio => radio.checked);

      if (selectedPreviousOption) {
        body.selected_previous_option = selectedPreviousOption.value;
      }
    }

    if (this.hasRowsTarget) {
      body.rows = JSON.parse(this.rowsTarget.value);
    }

    if (this.hasColumnsTarget) {
      body.columns = JSON.parse(this.columnsTarget.value);
    }

    this.showSaving();

    fetch(this.savePathValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Network response was not ok.');
      }
    })
    .then(data => {
      setTimeout(() => {
        this.showSaved();
      }, 1000);
    })
    .catch(error => {
      setTimeout(() => {
        this.showError();
      }, 1000);
    });
  }

  showSaving() {
    this.savedTarget.classList.add('hidden')
    this.saveErrorTarget.classList.add('hidden')
    this.savingTarget.classList.remove('hidden')
  }

  showSaved() {
    this.savingTarget.classList.add('hidden')
    this.savedTarget.classList.remove('hidden')
  }

  showError() {
    this.savingTarget.classList.add('hidden')
    this.savedTarget.classList.add('hidden')
    this.saveErrorTarget.classList.remove('hidden')
  }
}
