import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['root', 'form']

  connect() {
  }

  submit() {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    } else {
      const event = new CustomEvent('filter-submit', { bubbles: true })
      this.rootTarget.dispatchEvent(event)
    }
  }
}
