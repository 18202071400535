import StrategyFactory from './guess/strategy_factory.js'

const guessedCategories = ['expenses', 'liabilities_and_equity', 'revenue']

export default {
  signs: (values, signs) => {
    signs['assets'] = 1

    guessedCategories.forEach((k) => {
      // Find the sum of all values in the category.
      // If it is positive, the sign is 1, otherwise -1.
      // Sum by 'cents' field
      const sum = values[k].reduce((acc, v) => acc + v.cents, 0)
      signs[k] = sum > 0 ? 1 : -1
    })

    return signs
  },

  // `values` is an array of objects with the following format:
  // {
  //   account: 5046
  //   cents: 123420,
  //   sign: 1
  // }
  flips: (values, difference) => {
    // The algorithm tries multiple strategies to find out the one that
    // makes the difference equal to zero by flipping individual values.

    const strategies = ['TwoIslands', 'FlipExpensesDesc'];

    for (let i = 0; i < strategies.length; i++) {
      const strategy = strategies[i]

      const copy = JSON.parse(JSON.stringify(values))
      const found = StrategyFactory.create(strategy).run(copy, difference)

      if (found) {
        console.log(`Strategy ${strategy} found ${found.length} flips`)
        // Found is an array of values with changed `flipped` property
        return found
      }
    }

    // If no strategy was successful
    console.log('All strategies failed')
    return false
  }
}
