import Utils from '../../utils.js'

export default class FlipExpensesDesc {
  run(groupedValues, difference) {
    console.log(`FlipExpensesDesc strategy v0.1.`)
    console.log(`  ⚖️ Difference: ${difference}`)

    const suspiciousGroups = [
      [6950, 6999],
      [7000, 7009],
      [7500, 7509],
      [8100, 8499],
      [8510, 8599],
      [8610, 8699],
      [8710, 8899]
    ]

    // Convert to flat array
    const values = Object.values(groupedValues).flat()
    const filtered = Utils.filterFlippableAccounts(values)

    const rangeMapped = filtered.map((v) => {
      const ranges = suspiciousGroups.map((r) => {
        if (v.account >= r[0] && v.account <= r[1]) {
          return 0
        }

        if (v.account < r[0]) {
          return r[0] - v.account
        }

        if (v.account > r[1]) {
          return v.account - r[1]
        }
      })

      const min = Math.min(...ranges)

      return {
        value: v,
        range: min
      }
    })

    console.log('  -> rangeMapped', rangeMapped)

    function sortByAccountThenDiff(a, b) {
      if (a.range < b.range) return -1;
      if (a.range > b.range) return 1;

      const diffA = a.cents * 2 - difference
      const diffB = b.cents * 2 - difference

      if (diffA < diffB) return -1;
      if (diffA > diffB) return 1;

      return 0;
    }

    const sorted = rangeMapped.sort(sortByAccountThenDiff).map((v) => v.value)
    const sortedValues = sorted.slice(0, 18)

    console.log('  -> sortedValues', sortedValues)

    return Utils.flipCombinationTree(sortedValues, difference)
  }
}
