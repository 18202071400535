import FormulaFactory from './formula_factory';

export default class Cell {
  constructor(brain, cellConfig, row, id) {
    Object.assign(this, cellConfig);

    this.id = id
    this.columnKey = cellConfig.columnKey
    this.brain = brain
    this.originalFormula = cellConfig.formula
    this.formula = cellConfig.formula ? FormulaFactory.build(JSON.parse(JSON.stringify(cellConfig.formula))) : null;
    this.dependencies = [];
    this.dependents = [];
    this.editable = cellConfig.editable || false;
    this.format = cellConfig.format || { type: 'text' };
    this.style = cellConfig.style || {};
    this.evaluated = !this.formula && !this.expected;
    this.valid = true;
    this.circular = false;
    this.tags = cellConfig.tags || [];
    this.width = cellConfig.width || null;

    if (row.tags) {
      this.tags = row.tags.concat(this.tags);
    }
    this.hasExpectationError = false
    if (this.expected && this.expected.formula) {
      this.expectedFormula = FormulaFactory.build(JSON.parse(JSON.stringify(this.expected.formula)));
    }

    this.eventListeners = []
  }

  serialize() {
    return {
      id: this.id,
      value: this.value,
      formula: this.originalFormula,
      editable: this.editable,
      format: this.format,
      style: this.style,
      expected: this.expected,
      tags: this.tags,
      key: this.key,
      columnKey: this.columnKey,
    }
  }

  evaluate() {
    if (this.evaluated) {
      return;
    }

    if (!this.valid) {
      this.evaluated = true;
      return;
    }

    if (this.circular) {
      this.evaluated = true;
      return;
    }

    if (this.formula) {
      const result = this.formula.evaluate(this.dependencies);

      this.value = result.value;
      this.valid = result.valid;
    }

    this.hasExpectationError = !this.calculateExpectation()

    this.evaluated = true;
    this.notify('change', this.value);
  }

  notify(event, payload) {
    this.eventListeners.filter(l => l.event === event).forEach(l => l.callback(payload));
  }

  subscribe(event, callback) {
    this.eventListeners.push({ event, callback });
  }

  changeValue(newValue) {
    this.value = newValue;

    this.evaluated = false;
    this.invalidate()

    this.brain.onCellChanged(this)
  }

  invalidate() {
    this.evaluated = false;
    this.dependents.forEach(d => d.invalidate());
  }

  calculateExpectation() {
    if (!this.expected) {
      return true
    }

    if (this.expectedFormula) {
      const result = this.expectedFormula.evaluate();
      this.expectedValue = result.value;
    } else {
      this.expectedValue = this.expected.value;
    }

    switch(this.expected.type) {
      case 'must_be_equal':
        return this.value === this.expectedValue
      case 'must_be_greater_than':
        return this.value > this.expectedValue
      case 'must_be_greater_than_or_equal_to':
        return this.value >= this.expectedValue
      case 'must_be_less_than':
        return this.value < this.expectedValue
      case 'must_be_less_than_or_equal_to':
        return this.value <= this.expectedValue
      default:
        throw new Error(`Unknown expectation type: ${this.expected.type}`)
    }
  }

  expectationErrorSymbol() {
    if (!this.expected) {
      return ''
    }

    switch(this.expected.type) {
      case 'must_be_equal':
        return '!='
      case 'must_be_greater_than':
        return '<='
      case 'must_be_greater_than_or_equal_to':
        return '<'
      case 'must_be_less_than':
        return '>='
      case 'must_be_less_than_or_equal_to':
        return '>'
      default:
        throw new Error(`Unknown expectation type: ${this.brainCell.expected.type}`)
    }
  }
}
