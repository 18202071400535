const debug = (...args) => {
  console.log(...args)
}

let combinations = 0

export default {
  flipCombinationTree(values, difference) {
    const time = performance.now()
    for (let i = 0; i < Math.pow(2, values.length); i++) {
      let newDifference = difference

      for (let j = 0; j < values.length; j++) {
        if (i & (1 << j)) {
          newDifference += 2 * values[j].cents * (-values[j].sign)
        }
      }

      if (newDifference === 0) {
        console.log(`Time: ${performance.now() - time} ms`)
        console.log(`Combinations: ${combinations}`)
        console.log("FOUND", i.toString(2).padStart(values.length, '0'))

        const items = []

        for (let j = 0; j < values.length; j++) {
          if (i & (1 << j)) {
            items.push(values[j])
          }
        }

        return items
      } else {
        combinations++
      }
    }

    console.log(`Time: ${performance.now() - time} ms`)
    console.log(`Combinations: ${combinations}`)
    return false
  },

  sortByDifference(a, b, difference) {
    const diffA = a.cents * 2 - difference
    const diffB = b.cents * 2 - difference

    return diffA - diffB
  },

  filterFlippableAccounts(values) {
    return values.filter((v) => {
      let account = String(v.account);

      if (account.length < 4) {
        account = account.padEnd(4, '0');
      } else if (account.length > 4) {
        account = account.substring(0, 4);
      }
      return Number(account) >= 6899;
    });
  }
}
