import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["submitButton"]

  connect() {
    console.log("dgsgg")
    this.validationErrors = {};
    this.element.addEventListener('validation-error', this.handleValidationError.bind(this));
  }

  handleValidationError(event) {
    const { key, error } = event.detail;

    if (error) {
      this.validationErrors[key] = error;
    } else {
      delete this.validationErrors[key];
    }

    this.updateSubmitButtonState();
  }

  updateSubmitButtonState() {
    const hasErrors = Object.keys(this.validationErrors).length > 0;
    this.submitButtonTarget.disabled = hasErrors;
  }

  disconnect() {
    this.element.removeEventListener('validation-error', this.handleValidationError.bind(this));
  }
}
