import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "roleSelect", "clientsSelect" ]

  connect() {
    console.log('Roles controller connected')
    this.roleSelectTarget.addEventListener('change', this.checkRole.bind(this))
  }

  checkRole() {
    console.log('Role selected:', this.roleSelectTarget.value)
    if (this.roleSelectTarget.value == 'auditor') {
      this.clientsSelectTarget.style.display = 'block'
    } else {
      this.clientsSelectTarget.style.display = 'none'
    }
  }
}
