import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'clear']

  submit() {
    this.element.closest('form').requestSubmit()
  }

  clear() {
    this.inputTarget.value = ''
    this.submit()
    this.updateClearVisibility()
  }

  updateClearVisibility() {
    this.clearTarget.classList.toggle('hidden', this.inputTarget.value === '')
  }
}
