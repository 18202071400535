import { Controller } from "@hotwired/stimulus";
import Brain from './meow_matrix/brain'
import Renderer from './meow_matrix/renderer'

export default class extends Controller {
  static targets = ['root', 'templates', 'undoButton', 'redoButton', 'rows', 'columns'];
  static values = { config: Object, noteId: String };

  connect() {
    console.log("MeowMatrixController connected")
    console.log(this.configValue, this.noteIdValue)
    this.brain = new Brain(this.configValue);
    this.rootTarget.innerHTML = "Meow!";
    const undoButton = this.hasUndoButtonTarget ? this.undoButtonTarget : null;
    const redoButton = this.hasRedoButtonTarget ? this.redoButtonTarget : null;

    this.renderer = new Renderer(this.noteIdValue, this.brain, this.rootTarget, this.templatesTarget, undoButton, redoButton, this.rowsTarget, this.columnsTarget);
  }

  disconnect() {
    this.renderer.destroy();
  }

  addColumn() {
    this.brain.addColumn(
      { align: 'right', colspan: '140px', deletable: true },
      { title: 'New column' },
      {  }
    );
  }

  undo() {
    this.brain.undo();
  }

  redo() {
    this.brain.redo();
  }
}
