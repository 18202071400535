// Import Stimulus
import { Controller } from "@hotwired/stimulus";
import dayjs from "dayjs";

export default class extends Controller {
  static targets = ["year", "startDate", "endDate"];

  connect() {
    if (!this.hasYearTarget) {
      return;
    }

    this.updateDates()
    this.yearTarget.addEventListener("input", () => this.updateDates());
  }

  updateDates() {
    const year = parseInt(this.yearTarget.value, 10);
    if (isNaN(year) || year <= 0) {
      return;
    }

    // Set the start date to the beginning of the year
    // Without time component
    const startDate = dayjs(`${year}-01-01`)
    this.startDateTarget.value = startDate.format("YYYY-MM-DD");

    const endDate = startDate.endOf("year");
    this.endDateTarget.value = endDate.format("YYYY-MM-DD");
  }
}
