import { Controller } from '@hotwired/stimulus'
import Guess from './guess.js'

export default class SignsController extends Controller {
  static targets = ['navigation', 'submit', 'submitDisabled', 'explainGuesses', 'explainNonZeroSum', 'guessIndicator', 'guessIndicatorNumber',
                    'sum', 'flip', 'dataInput', 'signsInput']

  static values = {
    data: Object
  }

  static categories = ['assets', 'expenses', 'liabilities_and_equity', 'revenue']


  connect() {
    console.log("Import::SignsController#connect")
    console.log(this.dataValue)

    this.currentValues = {}
    this.accountValueMap = {}

    SignsController.categories.forEach((k) => {
      this.currentValues[k] = this.dataValue.rows[k]

      this.currentValues[k].forEach((v) => {
        this.accountValueMap[v.account] = v
      })
    })

    this.defaultSigns = this.dataValue.signs
    this.currentSigns = Guess.signs(this.currentValues, JSON.parse(JSON.stringify(this.defaultSigns)))

    SignsController.categories.forEach((k) => {
      document.querySelector('[data-import--signs-sign="' + k + '"]').value = this.currentSigns[k]
    })

    console.log(this.currentValues)

    this.guessFlips()
    this.unsubscribe = []

    this.navigationTargets.forEach((e) => {
      e.addEventListener('click', this.onNavigationClick)
      this.unsubscribe.push([e, 'click', this.onNavigationClick])
    })

    SignsController.categories.forEach((k) => {
      const handler = this.onSignChange.bind(this)
      const element = document.querySelector('[data-import--signs-sign="' + k + '"]')
      element.addEventListener('change', handler)
      this.unsubscribe.push([element, 'change', handler])
    })

    this.flipTargets.forEach((e) => {
      const handler = this.onFlipClick.bind(this)
      e.addEventListener('click', handler)
      this.unsubscribe.push([e, 'click', handler])
    })

    // ad handler to submit button
    const submitButton = document.querySelector('[type="submit"]')
    submitButton.addEventListener('click', e => {
      if (this.difference != 0) {
        e.preventDefault()
        return false
      }
    })

    if (this.warningCounter > 0) {
      const scrollContainer = document.querySelector('[data-scroll-reset]')
      const handler = this.onScroll.bind(this)
      scrollContainer.addEventListener('scroll', handler)
      this.unsubscribe.push([scrollContainer, 'scroll', handler])
    }

    this.calculateSum()
  }

  disconnect() {
    this.unsubscribe.forEach((u) => {
      u[0].removeEventListener(u[1], u[2])
    })
  }

  guessFlips() {
    Object.keys(this.accountValueMap).forEach((k) => {
      if (this.accountValueMap[k].flipped) {
        this.accountValueMap[k].flipped = false
        this.flipCell(k)
      }
    })

    const diff = this.calculateSum()
    const flipGuess = Guess.flips(this.currentValues, diff)

    if (flipGuess && flipGuess.length > 0) {
      console.log(`Flips guessed: `, flipGuess);
      flipGuess.forEach((v) => {
        this.accountValueMap[v.account].flipped = true
        this.flipCell(v.account)

        const flipButton = document.querySelector('[data-import--signs-invert-value="' + v.account + '"]')
        flipButton.classList.add('decreases-warning-counter')

        console.log('[data-related-to-account="' + v.account + '"]')
        const rowsToHighlight = document.querySelectorAll('[data-related-to-account="' + v.account + '"]');
        rowsToHighlight.forEach((e) => {
          setTimeout(() => {
            e.classList.add('has-warning')
          }, 1000)
        });
      });

      this.explainGuessesTarget.classList.remove('hidden')
      this.guessIndicatorTarget.classList.remove('hidden')
      this.guessIndicatorNumberTarget.innerHTML = flipGuess.length
      this.warningCounter = flipGuess.length

      this.calculateSum()
    }
  }

  hideAllWarnings() {
    const elements = document.querySelectorAll('.has-warning')
    elements.forEach((e) => {
      e.classList.remove('has-warning')
    })
    this.guessIndicatorTarget.classList.add('hidden')
    this.explainGuessesTarget.classList.add('hidden')
  }

  updateFormState() {
    if (this.difference == 0) {
      this.submitTarget.classList.remove('hidden')
      this.submitDisabledTarget.classList.add('hidden')

      this.explainNonZeroSumTarget.classList.add('hidden')
    } else {
      this.submitTarget.classList.add('hidden')
      this.submitDisabledTarget.classList.remove('hidden')

      this.explainNonZeroSumTarget.classList.remove('hidden')
    }
  }

  flipCell(account) {
    const cellToInvert = document.querySelector('[data-import--signs-invert-cell="' + account + '"]')
    if (cellToInvert.innerHTML.trim().startsWith('-')) {
      cellToInvert.innerHTML = cellToInvert.innerHTML.trim().substring(1)
    } else {
      cellToInvert.innerHTML = '-' + cellToInvert.innerHTML.trim()
    }

    const flipButton = document.querySelector('[data-import--signs-invert-value="' + account + '"]')
    flipButton.classList.toggle('is-selected')

    this.hideAllWarnings()
    this.calculateSum()
  }

  onScroll(e) {
    // If warningCounter is not 0, we decrease it by 1 each time user scrolls to an element with class .decreases-warning-counter
    if (this.warningCounter > 0) {
      const elements = document.querySelectorAll('.decreases-warning-counter')
      elements.forEach((e) => {
        if (e.getBoundingClientRect().top < window.innerHeight) {
          this.warningCounter -= 1
          e.classList.remove('decreases-warning-counter')
          this.guessIndicatorNumberTarget.innerHTML = this.warningCounter

          if (this.warningCounter == 0) {
            this.guessIndicatorTarget.classList.add('hidden')
          }
        }
      })
    }
  }

  onFlipClick(e) {
    var category = e.currentTarget.getAttribute('data-import--signs-invert-category')
    var account = e.currentTarget.getAttribute('data-import--signs-invert-value')
    var row = e.currentTarget.getAttribute('data-import--signs-invert-row')

    this.currentValues[category][row].flipped = !this.currentValues[category][row].flipped

    this.flipCell(account)
    this.calculateSum()
  }

  onSignChange(e) {
    var category = e.currentTarget.getAttribute('data-import--signs-sign')

    this.currentSigns[category] = +e.currentTarget.value

    console.log(category)

    this.hideAllWarnings()
    this.guessFlips()
  }

  onNavigationClick(e) {
    document.querySelector('[data-scroll-reset]').scrollTo(0, 0);
    var anchor = document.querySelector('[data-anchor="' + e.currentTarget.getAttribute('data-jump-to')+ '"]');

    if (anchor) {
      anchor.scrollIntoView()
    }

    return false;
  }

  // comma every 3 digits, leading 2 digits after dot
  formatFinancial(cents) {
    var s = (cents / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    if (s.indexOf('.') == -1) {
      s = s + '.00'
    }

    return s
  }

  calculateCategorySum(category) {
    var sum = 0

    this.currentValues[category].forEach((v) => {
      var value = v.cents
      var signInverted = 1

      if (v.flipped) {
        value = value * -1
        signInverted = signInverted * -1
      }

      if (this.currentSigns[category] != this.defaultSigns[category]) {
        value = value * -1
        signInverted = signInverted * -1
      }

      v.sign = signInverted
      v.actualCents = value

      sum = sum + value
    })

    document.querySelector('[data-import--signs-sum="' + category + '"]').innerHTML = this.formatFinancial(sum)

    return sum
  }

  calculateSum() {
    var sum = 0;

    SignsController.categories.forEach((k) => {
      sum = sum + this.calculateCategorySum(k);
    })

    this.difference = sum
    this.sumTarget.innerHTML = this.formatFinancial(sum)

    // data has format: [{ account: 1000, cents: 1560024, category: 'expenses', flipped: true }]
    // signs has format: { assets: 1, liabilities_and_equity: -1, revenue: -1, expenses: 1 }
    const data = []

    SignsController.categories.forEach((k) => {
      this.currentValues[k].map((v) => {
        data.push({
          account: v.account,
          cents: v.cents,
          category: k,
          flipped: v.flipped,
          description: v.description,
          actual_cents: v.actualCents
        })
      })
    })

    this.dataInputTarget.value = JSON.stringify(data)
    this.signsInputTarget.value = JSON.stringify(this.currentSigns)

    this.updateFormState()

    return sum
  }
}
