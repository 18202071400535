import RenderedCell from './rendered_cell';
import DragDrop from './utils/drag_drop';
// Meow renderer, as opposed to the brain, only focuses on presenting the values
// according to the formatter, specified in the cell; as well as updating the cell's
// value when it's changed by the user.

// So it takes the brain as an input, takes its cells and manages their DOM counterparts
// together with all needed events.

export default class Renderer {
  constructor(id, brain, root, templatesEl, undoButton, redoButton, rowsInput, columnsInput) {
    this.id = id
    this.root = root;
    this.brain = brain;
    this.undoButton = undoButton;
    this.redoButton = redoButton;
    this.rowsInput = rowsInput;
    this.columnsInput = columnsInput;

    // This is a collection of invisible elements that this class will copy when rendering
    // the cells. This is done to avoid having to write HTML in JS.
    this.templatesEl = templatesEl;

    this.rebuildRows();

    this.addDragDrop(brain);
    this.changeUndoRedoVisibility();

    this.brain.subscribe('row-added', (payload) => {
      const row = this.buildRow(payload.row, false);

      // same in DOM:
      const rows = this.root.querySelectorAll('tbody tr');
      const beforeRow = rows[payload.position - 1];
      const parent = beforeRow.parentNode;

      parent.insertBefore(row, beforeRow);
    })

    this.rowsInput.value = JSON.stringify(this.brain.saveData.rows);
    this.columnsInput.value = JSON.stringify(this.brain.saveData.columns);

    this.brain.subscribe('data-changed', (payload) => {
      this.rowsInput.value = JSON.stringify(this.brain.saveData.rows);
      this.columnsInput.value = JSON.stringify(this.brain.saveData.columns);
    })

    this.brain.subscribe('save-requested', (payload) => {
      this.root.dispatchEvent(new CustomEvent('save', { bubbles: true }));
    })

    this.root.dispatchEvent(new CustomEvent('validation-error', { bubbles: true, detail: { key: this.id, error: this.brain.hasExpectationErrors } }));
    this.brain.subscribe('expectation-errors-changed', (payload) => {
      this.root.dispatchEvent(new CustomEvent('validation-error', { bubbles: true, detail: { key: this.id, error: payload.hasExpectationErrors } }));
    })

    this.brain.subscribe('row-deleted', (payload) => {
      const rows = this.root.querySelectorAll('tbody tr');
      const row = rows[payload.position];
      row.parentNode.removeChild(row);
    })

    this.brain.subscribe('column-added', (payload) => {
      this.rebuildRows();
    })
    this.brain.subscribe('column-deleted', (payload) => {
      this.rebuildRows();
    })
    this.brain.subscribe('history-restored', (payload) => {
      this.rebuildRows();
      this.changeUndoRedoVisibility()
    })
    this.brain.subscribe('history-changed', (payload) => {
      this.changeUndoRedoVisibility()
    })
  }

  rebuildRows() {
    this.root.innerHTML = '<thead></thead><tbody></tbody>';
    this.rowMap = {};
    const headerRow = this.buildHeader(this.brain.rows[0]);
    this.root.querySelector('thead').appendChild(headerRow);

    const rows = this.brain.rows.slice(1).map((row, i) => this.buildRow(row));
    const body = this.root.querySelector('tbody')
    rows.forEach((row, i) => {
      body.appendChild(row)
    });
  }

  buildHeader(columnRow) {
    const rowEl = document.createElement('tr');

    rowEl.appendChild(document.createElement('th')); // Handle column

    columnRow.cells.forEach((cell, j) => {
      const container = document.createElement('th');

      if (this.brain.columns[j].width) {
        container.style.width = `${this.brain.columns[j].width}`;
      }

      const rcell = new RenderedCell(this.brain, cell, this.templatesEl, this.brain.columns[j], true, j)
      const rootEl = rcell.rootElement;
      container.appendChild(rootEl);
      rowEl.appendChild(container);
    });

    rowEl.appendChild(document.createElement('th')); // Actions column

    return rowEl;
  }

  buildRow(row) {
    const rowEl = document.createElement('tr');

    if (row.inserter) {
      return this.buildInserter(row, rowEl)
    }

    if (row.reorder) {
      rowEl.dataset.reorderGroup = row.reorder.section;
    }

    rowEl.dataset.testTags = row.tags;
    rowEl.dataset.testSection = row.section;

    this.addDragHandle(row, rowEl, false)

    row.cells.forEach((cell, j) => {
      const container = document.createElement('td');

      const rcell = new RenderedCell(this.brain, cell, this.templatesEl, this.brain.columns[j], false, j)
      const rootEl = rcell.rootElement;
      container.appendChild(rootEl);
      container.dataset.testTags = cell.tags;
      container.dataset.testInitialValue = cell.value;

      rowEl.appendChild(container);

      cell.subscribe('change', () => {
        console.log("Cell changed, re-rendering", cell)
        rcell.updateViewer();

        // Apply an animated quick pulse effect to the cell
        rootEl.classList.add('pulse');
        setTimeout(() => rootEl.classList.remove('pulse'), 1000);
      })
    });

    this.addActions(row, rowEl, false)

    return rowEl;
  }

  addDragHandle(row, rowEl, isHeader) {
    const dragHandleTemplate = this.templatesEl.querySelector(`[data-meow-template="${row.reorder ? 'drag' : 'no-drag'}-handle"]`);
    const dragHandleEl = dragHandleTemplate.cloneNode(true);
    const container = document.createElement(isHeader ? 'th' : 'td');
    container.style.width = '40px';
    container.appendChild(dragHandleEl);

    rowEl.appendChild(container);

    if (row.reorder) {
      dragHandleEl.classList.add('is-draggable');
    }
  }

  addActions(row, rowEl, isHeader) {
    const actionsTemplate = this.templatesEl.querySelector(`[data-meow-template="actions"]`);
    const actionsEl = actionsTemplate.cloneNode(true);
    const container = document.createElement(isHeader ? 'th' : 'td');
    container.style.width = '150px';
    container.appendChild(actionsEl);

    const deleteButton = actionsEl.querySelector('[data-meow-role="delete"]');
    if (row.deletable) {
      deleteButton.classList.remove('hidden');
      deleteButton.addEventListener('click', () => {
        this.brain.deleteRow(row);
      })
    }

    rowEl.appendChild(container);
  }

  addDragDrop(brain) {
    this.dragDrop = new DragDrop(brain)

    this.onMouseDownHandler = this.onMouseDown.bind(this)
    this.onMouseMoveHandler = this.onMouseMove.bind(this)
    this.onMouseUpHandler = this.onMouseUp.bind(this)

    window.addEventListener('mousedown', this.onMouseDownHandler);
    window.addEventListener('mousemove', this.onMouseMoveHandler);
    window.addEventListener('mouseup', this.onMouseUpHandler);

    this.brain.subscribe('row-moved', (payload) => {
      const { from, to } = payload;

      const rows = this.root.querySelectorAll('tbody tr');
      const fromRow = rows[from - 1]; // -1 because the header row is in head, not body
      const toRow = rows[to - 1];
      const parent = fromRow.parentNode;

      from > to ? parent.insertBefore(fromRow, toRow) : parent.insertBefore(fromRow, toRow.nextSibling);
    });
  }

  buildInserter(row, rowEl) {
    const inserterTemplate = this.templatesEl.querySelector(`[data-meow-template="inserter"]`);
    const el = inserterTemplate.cloneNode(true);

    const container = document.createElement('tr');
    container.appendChild(document.createElement('td'));

    container.dataset.testTags = row.tags;
    container.dataset.testInserter = true

    const buttonCell = document.createElement('td')
    buttonCell.appendChild(el);
    container.appendChild(buttonCell);

    const remainingTDs = this.brain.columns.length;
    for(let i = 0; i < remainingTDs; i++) {
      container.appendChild(document.createElement('td'));
    }

    const button = el.querySelector('button');
    button.addEventListener('click', () => {
      const tr = button.closest('tr');
      this.brain.addRow(row, Array.prototype.indexOf.call(tr.parentNode.children, tr) + 1);
    })

    return container
  }

  addColumnAdder(parentEl) {
    const addColumnTemplate = this.templatesEl.querySelector(`[data-meow-template="add-column"]`);
    const el = addColumnTemplate.cloneNode(true);

    const button = el.querySelector('button');
    button.addEventListener('click', () => {
      this.brain.addColumn();
    })

    parentEl.appendChild(el);
  }

  changeUndoRedoVisibility() {
    if (!this.undoButton || !this.redoButton) {
      return
    }

    if (this.brain.canUndo()) {
      this.undoButton.removeAttribute('disabled');
    } else {
      this.undoButton.setAttribute('disabled', true);
    }

    if (this.brain.canRedo()) {
      this.redoButton.removeAttribute('disabled');
    } else {
      this.redoButton.setAttribute('disabled', true);
    }
  }

  onMouseDown(e) {
    if (this.root.contains(e.target) && e.target.closest('.is-draggable')) {
      const rowEl = e.target.closest('tr')

      // Find min / max index of elements with the same data-reorder-group as this element
      // so that we don't allow dragging outside of the group
      const reorderGroup = rowEl.dataset.reorderGroup
      const reorderGroupEls = this.root.querySelectorAll(`[data-reorder-group="${reorderGroup}"]`)

      const maxIndex = Array.from(reorderGroupEls).length - 1
      const rowId = Array.prototype.indexOf.call(reorderGroupEls, rowEl);

      const row = this.brain.rows[Array.prototype.indexOf.call(rowEl.parentNode.children, rowEl) + 1]

      this.dragDrop.startDragging(row, rowEl, reorderGroupEls, e.clientY, rowId, maxIndex)
    }
  }

  onMouseMove(e) {
    this.dragDrop.handleMouseMove(e)
  }

  onMouseUp(e) {
    this.dragDrop.handleMouseUp(e)
  }

  destroy() {
    window.removeEventListener('mousedown', this.onMouseDownHandler)
    window.removeEventListener('mousemove', this.onMouseMoveHandler)
    window.removeEventListener('mouseup', this.onMouseUpHandler)
  }
}
