import Utils from '../../utils.js'

export default class TwoIslands {
  run(groupedValues, difference) {
    console.log(`TwoIslands strategy v0.1.`)
    console.log(`  ⚖️ Difference: ${difference}`)

    // Convert to flat array
    const values = Object.values(groupedValues).flat()
    const filtered = Utils.filterFlippableAccounts(values)

    const suspiciousGroups = [
      [6950, 6999],
      [7000, 7009],
      [7500, 7509],
      [8100, 8499],
      [8510, 8599],
      [8610, 8699],
      [8710, 8899]
    ]

    const rangeMapped = filtered.map((v) => {
      const ranges = suspiciousGroups.map((r) => {
        if (v.account >= r[0] && v.account <= r[1]) {
          return 0
        }

        if (v.account < r[0]) {
          return r[0] - v.account
        }

        if (v.account > r[1]) {
          return v.account - r[1]
        }
      })

      const min = Math.min(...ranges)

      return {
        value: v,
        range: min
      }
    })

    console.log('  -> rangeMapped', rangeMapped)

    const sortedValues = rangeMapped.sort((a, b) => a.range - b.range).map((v) => v.value)

    console.log('  -> sortedValues', sortedValues)

    const start = performance.now();
    const cents = sortedValues.map((v) => v.cents)
    const flips = this.solve(cents, difference);

    if (flips && this.verify(cents, flips, difference)) {
      console.log(`Time: ${performance.now() - start} ms`)
      return flips.map((v) => sortedValues[v])
    }

    return false
  }

  randint(end) {
    Math.floor(Math.random() * end)
  }

  solve(amounts, difference) {
      let deadline = performance.now() + 1900;
      const map = new Map;

      function memo(sum, loc) {
          if (map.has(sum)) map.get(sum).push(loc);
          else map.set(sum, [loc]);
      }

      function recur(i, j, bits, width, sum) {
          if (width == 0) return memo(sum, [i, bits]);
          recur(i, j + 1, bits * 2, width - 1, sum);
          recur(i, j + 1, bits * 2 + 1, width - 1, sum + amounts[j]*2);
      }

      function expand([i, bits]) {
          return Array.from(bits.toString(2), (bit, j) => +bit ? i + j : -1)
                      .filter(i => i >= 0);
      }

      let sum = difference;
      if (sum == 0) return []; // No flips

      const WIDTH = 10;
      // Collect islands with at least one flip (at i)
      for (let i = 0; i < amounts.length; i++) {
          recur(i, i + 1, 1, WIDTH - 1, amounts[i]*2);
      }
      // Solution with one island?
      if (map.has(sum)) return expand(map.get(sum)[0]);
      // Look for solutions with two islands...
      for (let [sum1, islands] of map) {
          if (map.has(sum - sum1)) {
              for (let [i, bits1] of map.get(sum - sum1)) {
                  for (let [j, bits2] of islands) {
                      if (i >= j + WIDTH) return expand([j, bits2]).concat(expand([i, bits1]));
                      else if (j >= i + WIDTH) return expand([i, bits1]).concat(expand([j, bits2]));
                  }
              }
          }
          if (performance.now() >= deadline) break;
      }
      return null;
  }

  verify(amounts, flips, difference) {
    const sumOriginal = amounts.reduce((acc, val) => acc + val, 0);
    const sumFlipped = amounts.reduce((acc, val, i) => acc + (flips.includes(i) ? -val : val), 0);
    const sum = sumOriginal - sumFlipped;

    if (sum != difference) {
        console.log("Wrong solution detected! sum=" + sum)
        return false
    }

    return true
  }
}
