import { Controller } from '@hotwired/stimulus'

export default class GroupsController extends Controller {
  static targets = ['editGroup', 'mainView', 'dataInput',
                    'editGroupView', 'editGroupBack', 'editFieldAccount', 'editFieldDescription']

  static values = {
    data: Object,
    previousData: Object
  }

  static categories = ['assets', 'expenses', 'liabilities_and_equity', 'revenue']

  connect() {
    console.log("Import::GroupsController#connect")

    this.flatHierarchy = this.flattenHierarchy(this.dataValue.hierarchy)
    this.groupOverrides = {}
    this.unsubscribe = []

    this.previousDataValue.rows.forEach((row) => {
      if (row.override) {
        this.groupOverrides[row.account] = row.group
      }
    })

    this.editGroupTargets.forEach((e) => {
      const handler = this.onEditGroupClick.bind(this)
      e.addEventListener('click', handler)
      this.unsubscribe.push([e, 'click', handler])
    })

    const handler = this.onTreeSelected.bind(this)
    this.editGroupViewTarget.addEventListener('tree:select', handler)
    this.unsubscribe.push([this.editGroupViewTarget, 'tree:select', handler])

    this.recalculateDataInput()
    this.updateGroupOverrides()
  }

  disconnect() {
    this.unsubscribe.forEach((u) => {
      u[0].removeEventListener(u[1], u[2])
    })
  }

  recalculateDataInput() {
    const data = []

    GroupsController.categories.forEach((k) => {
      this.dataValue['rows_grouped'][k].forEach((v) => {
        const row = v.row
        if (this.groupOverrides[v.account]) {
          row.group = this.groupOverrides[v.account]
          row.override = true
        } else {
          row.group = v.group
        }
        data.push(row)
      })
    })

    this.dataInputTarget.value = JSON.stringify(data)
  }


  flattenHierarchy(hierarchy) {
    const flatHash = {}

    const addChildrenRecursively = (node, parent) => {
      const newNode = {
        label: node.label,
        value: node.value,
        parent: parent,
        children: []
      }
      flatHash[node.value] = newNode

      if (node.children) {
        node.children.forEach((item) => {
          addChildrenRecursively(item, newNode)
        })
      }
    }

    hierarchy.forEach((item) => {
      addChildrenRecursively(item, null)
    })

    return flatHash
  }

  onTreeSelected(e) {
    console.log("Import::GroupsController#onTreeSelected", e)
    const item = this.flatHierarchy[e.detail.id]
    const newGroup = item.value
    const newDescription = item.label

    this.groupOverrides[this.account] = newGroup

    this.updateGroupOverrides()

    this.closeEdit()
    this.recalculateDataInput()
  }

  updateGroupOverrides() {
    for (const [account, group] of Object.entries(this.groupOverrides)) {
      const newDescription = this.flatHierarchy[group].label

      // If such selector not found — skip to next account
      if (!this.mainViewTarget.querySelector(`[data-import--groups-row-group-for="${account}"]`)) {
        continue
      }

      this.mainViewTarget.querySelector(`[data-import--groups-row-group-for="${account}"]`).innerHTML = group
      this.mainViewTarget.querySelector(`[data-import--groups-row-group-title-for="${account}"]`).innerHTML = newDescription
      this.mainViewTarget.querySelector(`[data-import--groups-row-asterisk-for="${account}"]`).classList.remove('hidden')
      this.mainViewTarget.querySelector(`[data-import--groups-row-group-for="${account}"]`).classList.add('!bg-warning')
      this.mainViewTarget.querySelector(`[data-import--groups-row-group-title-for="${account}"]`).classList.add('!text-warning')
    }
  }

  onEditGroupClick(e) {
    var account = e.currentTarget.getAttribute('data-import--groups-row-account')
    var description = e.currentTarget.getAttribute('data-import--groups-row-description')

    this.editFieldAccountTarget.innerHTML = account
    this.editFieldDescriptionTarget.innerHTML = description

    this.account = account

    this.mainViewTarget.classList.add('hidden')
    this.editGroupViewTarget.classList.remove('hidden')
  }

  closeEdit(e) {
    this.mainViewTarget.classList.remove('hidden')
    this.editGroupViewTarget.classList.add('hidden')

    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    return false
  }
}
