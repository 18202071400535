export default class DragDrop {
  constructor(brain) {
    this.brain = brain
  }

  startDragging(row, rowEl, otherRows, clientY, rowIndex, maxIndex) {
    this.isDragging = true
    this.draggedRow = row
    this.draggedRowEl = rowEl
    this.otherRows = otherRows
    this.draggedRowIndex = rowIndex

    this.rowHeight = this.draggedRowEl.children[0].clientHeight; // Assuming all rows have the same height

    this.minIndexBoundary = 0
    this.maxIndexBoundary = maxIndex

    console.log(`Starting to drag row ${rowIndex} with minIndexBoundary ${this.minIndexBoundary} and maxIndexBoundary ${this.maxIndexBoundary}`)
    this.startY = clientY

    document.body.classList.add('no-select');
  }

  calculateCurrentIndex(clientY) {
    const deltaY = clientY - this.startY;
    this.currentY = clientY;

    let currentIndex = Math.round(deltaY / this.rowHeight) + this.draggedRowIndex;
    currentIndex = Math.max(0, Math.min(currentIndex, this.maxIndexBoundary));

    return currentIndex;
  }

  handleMouseMove(e) {
    if (!this.isDragging) {
      return;
    }

    const deltaY = e.clientY - this.startY;
    this.currentY = e.clientY;

    let currentIndex = this.calculateCurrentIndex(e.clientY);
    this.applyTransformToRows(currentIndex);

    // if currentIndex is less/more than 0/max, then do not show translate larger than these
    const correctedDeltaY = Math.max(-this.draggedRowIndex * this.rowHeight, Math.min(deltaY, (this.maxIndexBoundary - this.draggedRowIndex) * this.rowHeight));

    // Apply transform to the dragged row
    const directChildren = this.draggedRowEl.children;
    Array.from(directChildren).forEach(el => el.style.transform = `translateY(${correctedDeltaY}px)`);
  }

  applyTransformToRows(currentIndex) {
    this.otherRows.forEach((row, index) => {
      if (index !== this.draggedRowIndex && index >= Math.min(this.draggedRowIndex, currentIndex) && index <= Math.max(this.draggedRowIndex, currentIndex)) {
        const transformValue = index < this.draggedRowIndex ? this.rowHeight : -this.rowHeight;
        Array.from(row.children).forEach(el => el.style.transform = `translateY(${transformValue}px)`);
      } else if (index !== this.draggedRowIndex) {
        Array.from(row.children).forEach(el => el.style.transform = ``);
      }
    });
  }

  handleMouseUp(e) {
    if (!this.isDragging) {
      return;
    }

    let currentIndex = this.calculateCurrentIndex(e.clientY);
    this.brain.moveRow(this.draggedRow, currentIndex - this.draggedRowIndex);

    this.isDragging = false;

    // Reset transforms for all rows
    this.otherRows.forEach((row, index) => {
      const children = row.children;
      Array.from(children).forEach(el => el.style.transform = ``);
    });

    this.draggedRowIndex = null;
    this.startY = null;

    document.body.classList.remove('no-select');
  }
}
