import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  static targets = [ "input" ]
  static values = {
    account: String
  }

  connect() {
    console.log(this.accountValue)
    console.log(this.inputTarget)
    this.directUploadUrl = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  }

  upload() {
    var input = this.inputTarget

    var files = input.files


    // TODO: for each file send post request to the backend with value from the  account-value
  }
}
