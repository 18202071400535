// app/javascript/controllers/collapse_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["shown", "autoFocus"];
  static values = { isExpanded: Boolean };

  connect() {
    this.updateView();
  }

  toggle() {
    this.isExpandedValue = !this.isExpandedValue;
    this.updateView();

    if (this.isExpandedValue) {
      if (this.hasAutoFocusTarget) {
        this.autoFocusTarget.focus();
      }
    }
  }

  updateView() {
    if (this.isExpandedValue) {
      this.element.classList.add("is-expanded");
      this.element.classList.remove("is-collapsed");
    } else {
      this.element.classList.remove("is-expanded");
      this.element.classList.add("is-collapsed");
    }

    this.shownTargets.forEach(element => {
      const showWhen = element.dataset.showWhen;

      if (this.isExpandedValue) {
        if (showWhen === "expanded") {
          element.classList.remove("hidden");
        } else {
          element.classList.add("hidden");
        }
      } else {
        if (showWhen === "expanded") {
          element.classList.add("hidden");
        } else {
          element.classList.remove("hidden");
        }
      }
    });
  }
}
