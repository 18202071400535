import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { delay: Number }

  submit() {
    setTimeout(() => {
      this.element.closest('form').requestSubmit()
    }, this.delayValue || 0)
  }
}
