import Sum from './formulas/sum';

export default class FormulaFactory {
  static build(formulaConfig) {
    switch(formulaConfig.type) {
      case 'sum':
        return new Sum(formulaConfig.terms);
      default:
        throw new Error(`Unknown formula type: ${formulaConfig.type}`);
    }
  }
}
